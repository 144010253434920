

import React from 'react';
import { Card, Container, Row } from "reactstrap";
import AbonnemntType from "../../components/AbonnementType/AbonnemntType";
import ListAbonnemnt from "../../components/ListAbonnement/ListAbonnement";
//import AbonnementPagination from "../../components/AbonnementPagination/AbonnementPagination";


function Tables() {
  return (
    <>
      {/*<Header />*/}
      <AbonnemntType />
      {/*filter*/}
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <ListAbonnemnt />
              {/*<AbonnementPagination />*/}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );

}

export default Tables;
