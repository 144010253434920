import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import cc from "classnames";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import axios from "axios";

function MatchTypeJso() {
  const host = process.env.REACT_APP_HOST;

  const [statisticsJso, setStatisticsJso] = useState();

  useEffect(() => {
    axios
      .get(`${host}/api/card/get-cards-statistics`, {})
      .then(function (response) {
        setStatisticsJso(response.data.data.matchJso);
      })

      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div
        className={cc(
          styles.header,
          "header bg-gradient-info pb-8 pt-5 pt-md-8"
        )}
      >
        <Container Container>
          <div className="header-body">
            <Row style={{ rowGap: "20px" }}>
              <Col lg="3" xl="2" md="4" xs="4">
                <div className={styles.cardbronze}>
                  <h1 className={styles.type}>Bon des soutiens</h1>{" "}
                  {statisticsJso?.soldAll ? statisticsJso?.soldAll : 0}
                  {""}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default MatchTypeJso;
