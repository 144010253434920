import React from "react";
import { useTable, usePagination } from "react-table";
import { Button, CardFooter, Table } from "reactstrap";

const CustomTable = ({ columns, data, RowComponent }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  return (
    <div>
      <Table
        className="align-items-center table-flush"
        responsive
        {...getTableProps()}
      >
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {data.length > 0 ? (
            page.map((row) => {
              prepareRow(row);
              return <RowComponent key={row.id} row={row} />;
            })
          ) : (
            <tr>
              <td
                colSpan={columns.length}
                style={{ textAlign: "center", padding: "20px" }}
              >
                <h4>Aucune donnée disponible</h4>
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <CardFooter className="py-4 d-flex flex-row justify-content-end align-items-center">
        <Button
          color="danger"
          outline
          size="sm"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          {"<<"}
        </Button>
        <Button
          color="danger"
          outline
          size="sm"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          {"<"}
        </Button>
        <Button
          color="danger"
          outline
          size="sm"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          {">"}
        </Button>
        <Button
          color="danger"
          outline
          size="sm"
          onClick={() => gotoPage(pageOptions.length - 1)}
          disabled={!canNextPage}
        >
          {">>"}
        </Button>
        <div>
          <span>
            Page {"  "}
            <strong>
              {pageIndex + 1} / {pageOptions.length} {"  "}
            </strong>
          </span>
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </div>
      </CardFooter>
    </div>
  );
};

export default CustomTable;
