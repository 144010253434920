import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import cc from "classnames";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import axios from "axios";

function AbonnemntType() {
  const host = process.env.REACT_APP_HOST;

  const [statisticsVirage, setStatisticsVirage] = useState();
  const [statisticsViragePlus, setStatisticViragePlus] = useState();
  const [statisticChinois, setStatisticChinois] = useState();
  const [statisticChinoisPlus, setStatisticChinoisPlus] = useState();
  const [statisticGradinPlus, setStatisticGradinPlus] = useState();
  const [statisticGradin, setStatisticGradin] = useState();
  const [statisticTribune, setStatisticTribune] = useState();
  const [statisticLoge, setStatisticLoge] = useState();
  const [statisticSportGradin, setStatisticSportGradin] = useState();
  const [statisticSportTribune, setStatisticSportTribune] = useState();
  const [totalAbonnements, setTotalAbonnements] = useState();

  useEffect(() => {
    axios
      .get(`${host}/api/card/get-cards-statistics`, {})
      .then(function (response) {
        setStatisticsVirage(response.data.data.virage);
        setStatisticViragePlus(response.data.data.viragePlus);
        setStatisticChinois(response.data.data.chinois);
        setStatisticChinoisPlus(response.data.data.chinoisPlus);
        setStatisticGradinPlus(response.data.data.gradinPlus);
        setStatisticGradin(response.data.data.gradin);
        setStatisticTribune(response.data.data.tribune);
        setStatisticLoge(response.data.data.loge);
        setStatisticSportGradin(response.data.data.sportGradin);
        setStatisticSportTribune(response.data.data.sportTribune);
        setTotalAbonnements(response.data.data.totalAbonnement);
      })

      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div
        className={cc(
          styles.header,
          "header bg-gradient-info pb-8 pt-5 pt-md-8"
        )}
      >
        <Container Container>
          <div className="header-body">
            {/* Card stats */}
            <Row style={{ rowGap: "20px", }}>
              <Col lg="3" xl="2" md="4" xs="4">
              <div className={styles.total}
          
            >
              <h1 className={styles.type}>Total abonnements</h1>{" "}
              {totalAbonnements ? totalAbonnements + " Abonnement" : 0}{" "}
            </div>
              </Col>{" "}
            </Row>
            <Row style={{ rowGap: "20px" }}>
              <Col lg="3" xl="2" md="4" xs="4">
                <div className={styles.cardbronze}>
                  <h1 className={styles.type}>CHINOIS</h1>{" "}
                  {statisticChinois?.soldAll ? statisticChinois?.soldAll : 0}
                  {""}
                </div>
              </Col>
              <Col lg="3" xl="2" md="4" xs="4">
                <div className={styles.cardgold}>
                  <h1 className={styles.type}>CHINOIS PLUS</h1>
                  {statisticChinoisPlus?.soldAll
                    ? statisticChinoisPlus?.soldAll
                    : 0}{" "}
                </div>
              </Col>
              <Col lg="3" xl="2" md="4" xs="4">
                <div className={styles.cardsilver}>
                  <h1 className={styles.type}>VIRAGE</h1>{" "}
                  {statisticsVirage?.soldAll ? statisticsVirage?.soldAll : 0}
                </div>
              </Col>
              <Col lg="3" xl="2" md="4" xs="4">
                <div className={styles.cardgold}>
                  <h1 className={styles.type}>VIRAGE PLUS</h1>
                  {statisticsViragePlus?.soldAll
                    ? statisticsViragePlus?.soldAll
                    : 0}{" "}
                </div>
              </Col>
              <Col lg="3" xl="2" md="4" xs="4">
                <div className={styles.cardbronze}>
                  <h1 className={styles.type}>GRADIN</h1>

                  {statisticGradin?.soldAll ? statisticGradin?.soldAll : 0}
                </div>
              </Col>
              <Col lg="3" xl="2" md="4" xs="4">
                <div className={styles.cardsilver}>
                  <h1 className={styles.type}>GRADIN PLUS</h1>{" "}
                  {statisticGradinPlus?.soldAll
                    ? statisticGradinPlus?.soldAll
                    : 0}
                </div>
              </Col>

              <Col lg="3" xl="2" md="4" xs="4">
                <div className={styles.cardbronze}>
                  <h1 className={styles.type}>TRIBUNE</h1>{" "}
                  {statisticTribune?.soldAll ? statisticTribune?.soldAll : 0}
                  {""}
                </div>
              </Col>
              <Col lg="3" xl="2" md="4" xs="4">
                <div className={styles.cardsilver}>
                  <h1 className={styles.type}>LOGE</h1>

                  {statisticLoge?.soldAll ? statisticLoge?.soldAll : 0}
                </div>
              </Col>
              <Col lg="3" xl="2" md="4" xs="4">
                <div className={styles.cardgold}>
                  <h1 className={styles.type}>SPORT GRADIN</h1>

                  {statisticSportGradin?.soldAll
                    ? statisticSportGradin?.soldAll
                    : 0}
                </div>
              </Col>
              <Col lg="3" xl="2" md="4" xs="4">
                <div className={styles.cardbronze}>
                  <h1 className={styles.type}>SPORT TRIBUNE</h1>

                  {statisticSportTribune?.soldAll
                    ? statisticSportTribune?.soldAll
                    : 0}
                </div>
              </Col>
            </Row>
      
          
          </div>
        </Container>
      </div>
    </>
  );
}

export default AbonnemntType;
