

import React from 'react';
import { Card, Container, Row } from "reactstrap";
import MatchType from 'components/MatchType/MatchType';
import ListMatchs from 'components/ListMatchs/ListMatchs';


function Matchs() {
  return (
    <>
      <MatchType />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <ListMatchs />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );

}

export default Matchs;
