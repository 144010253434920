import React, { useEffect, useMemo, useState } from "react";
import {
  Badge,
  Button,
  CardHeader,
  Col,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import axios from "axios";
import CustomTable from "components/reusable/customTable";
import Switch from "react-switch";

function ListAbonnemnt() {
  const [cards, setCards] = useState([]);
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [phone, setPhone] = useState("");
  const [booked, setBooked] = useState("false");

  const fetchAbonnements = async () => {


    const host = process.env.REACT_APP_HOST;
    try {
      const response = await axios.get(`${host}/api/card/get-cards-by`, {
        params: {
          type: type,
          email: email,
          phone: phone,
          booked: false, // Convert string to boolean
        },
      });
      setCards(response.data.abonnements);

      // const host = process.env.REACT_APP_HOST;
      // try {
      //   const response = await axios.get(`${host}/api/card/get-all-abonnement`);
      //   setCards(response.data.abonnements);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAbonnements();
  }, []);

  const handleSubmit = async () => {
    const host = process.env.REACT_APP_HOST;
    try {
      const response = await axios.get(`${host}/api/card/get-cards-by`, {
        params: {
          type: type,
          email: email,
          phone: phone,
          booked: booked === "true", // Convert string to boolean
        },
      });
      setCards(response.data.abonnements);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchPhone = (phone) => {
    setPhone(phone);
  };

  const handleToggle = async (item) => {
    const host = process.env.REACT_APP_HOST;
    const newStatus = !item.booked;
    try {
      await axios.post(`${host}/api/card/change-status-booked`, {
        status: newStatus,
        number: item.number,
      });

      // Refresh the list immediately after updating the status
      if (booked === "true") {
        handleSubmit();
      } else {
        fetchAbonnements();
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const paginatedCards = useMemo(() => {
    return cards;
  }, [cards]);

  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Nom",
        accessor: "userName",
      },
      {
        Header: "Adresse",
        accessor: "city",
      },
      {
        Header: "Tel",
        accessor: "phone",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Livré/Non livré",
        accessor: "booked",
      },
    ],
    []
  );

  return (
    <>
      <CardHeader>
        <CardHeader>
          <Row>
            <Col lg="2">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  id="input-email"
                  value={email}
                  onInput={(event) => setEmail(event.target.value)}
                  placeholder="example@example.com"
                  type="email"
                />
              </FormGroup>
            </Col>
            <Col lg="2">
              <FormGroup>
                <select
                  value={type}
                  onChange={(event) => setType(event.target.value)}
                  className="form-control-alternative form-control"
                >
                  <option value="">Sélectionner Type</option>
                  <option value="virage">Virage</option>
                  <option value="viragePlus">Virage Plus</option>
                  <option value="gradin">Gradin</option>
                  <option value="gradinPlus">Gradin Plus</option>
                  <option value="chinois">Chinois</option>
                  <option value="chinoisPlus">Chinois Plus</option>
                  <option value="tribune">Tribune</option>
                  <option value="loge">Loge</option>
                  <option value="sportGradin">Sport Gradin</option>
                  <option value="sportTribune">Sport Tribune</option>
                </select>
              </FormGroup>
            </Col>
            <Col lg="2">
              <FormGroup>
                <Input
                  className="form-control-alternative"
                  id="input-phone"
                  value={phone}
                  onInput={(event) => handleSearchPhone(event.target.value)}
                  placeholder="Téléphone"
                />
              </FormGroup>
            </Col>

            <Col lg="2">
              <FormGroup>
                <select
                  value={booked}
                  onChange={(event) => setBooked(event.target.value)}
                  className="form-control-alternative form-control"
                >
                  <option value="" disabled>
                    Sélectionner
                  </option>
                  <option value="true">Livré</option>
                  <option value="false">Non livré</option>
                </select>
              </FormGroup>
            </Col>

            <Col lg="2">
              <FormGroup>
                <Button
                  onClick={handleSubmit}
                  style={{
                    width: "100%",
                    background: "#d40000",
                    border: "#d40000",
                  }}
                  className="btn btn-info"
                  color="default"
                >
                  Rechercher
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </CardHeader>
      </CardHeader>
      <CardHeader className="border-0">
        <h3 className="mb-0">Liste des abonnements</h3>
      </CardHeader>

      <CustomTable
        columns={columns}
        data={paginatedCards}
        RowComponent={({ row }) => (
          <tr key={row.original.number}>
            <td>{row.original.email}</td>
            <td>{row.original.userName}</td>
            <td>{`${row.original.city}, ${row.original.country}`}</td>
            <td>{row.original.phone}</td>
            <td>{row.original.date.split("T")[0]}</td>
            <td>
              <Badge color="" className="badge-dot mr-4">
                <i className="bg-warning" />
                {row.original.type}
              </Badge>
            </td>
            <td style={{ display: "flex", alignrows: "center" }}>
              <Switch
                checked={row.original.booked}
                onChange={() => handleToggle(row.original)}
                onColor="#4caf50"
                offColor="#f44336"
                height={20}
                width={48}
              />
              <span style={{ marginLeft: "8px" }}>
                {row.original.booked ? "Livré" : "Non livré"}
              </span>
            </td>
          </tr>
        )}
      />
    </>
  );
}

export default ListAbonnemnt;
