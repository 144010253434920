// import Index from "views/Index.js";
// import Profile from "views/examples/Profile.js";
// import Maps from "views/examples/Maps.js";
// import Register from "views/examples/Register.js";
 import Login from "views/examples/Login.js";
// import Icons from "views/examples/Icons.js";

import Tables from "views/examples/Tables.js";
import Matchs from "views/examples/Matchs.js";
import MatchJso from "views/examples/MatchJso.js";

var routes = [
  {
    path: "/abonnements",
    name: "",
    icon: "",
    component: Tables,
    layout: "/admin",
  },
  // {
  //   path: "/index",
  //   name: "Dashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: Index,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: Profile,
  //   layout: "/admin"
  // },
  {
    path: "/abonnements",
    name: "Abonnements",
    icon: "ni ni-bullet-list-67 text-red",
    component: Tables,
    layout: "/admin",
  },
  {
    path: "/matchs",
    name: "Match ESS VS EST",
    icon: "ni ni-bullet-list-67 text-red",
    component: Matchs,
    layout: "/admin",
  },

  {
    path: "/matchJso",
    name: "Match ESS VS JSO",
    icon: "ni ni-bullet-list-67 text-red",
    component: MatchJso,
    layout: "/admin",
  },
  // {
  //   path: "/stock",
  //   name: "Stock",
  //   icon: "fa fa-archivetext-red",
  //   component: Tables,
  //   layout: "/admin"
  // },
  // {
  //   path: "/sales-history",
  //   name: "histrique des ventes",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: Tables,
  //   layout: "/admin"
  // },
  // {
  //   path: "/login",
  //   name: "Login",
  //   icon: "ni ni-key-25 text-info",
  //   component: Login,
  //   layout: "/auth"
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth"
  // }
];
export default routes;
