

import React from 'react';
import { Card, Container, Row } from "reactstrap";
import MatchType from 'components/MatchType/MatchType';
import MatchJsoType from 'components/MatchType/MatchTypeJso';
import ListMatchJso from 'components/ListMatchs/ListMatchJso';


function MatchJso() {
  return (
    <>
      <MatchJsoType />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <ListMatchJso />
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );

}

export default MatchJso;
