import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import cc from "classnames";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import axios from "axios";

function MatchType() {
  const host = process.env.REACT_APP_HOST;

  const [statisticsMatchVirage, setStatisticsMatchVirage] = useState();
  const [statisticsMatchGradin, setStatisticMatchGradin] = useState();
  const [statisticMatchChinois, setStatisticMatchChinois] = useState();
  const [statisticMatchTribune, setStatisticMatchTribune] = useState();
  const [statisticMatchLoge, setStatisticMatchLoge] = useState();
  const [totalMatchCards, setTotalMatchCards] = useState();
  const [coutTotal, setCoutTotal] = useState();
  const [totalCostMatchVirage, setTotalCostMatchVirage] = useState();
  const [totalCostMatchGradin, setTotalCostMatchGradin] = useState();
  const [totalCostMatchChinois, setTotalCostMatchChinois] = useState();
  const [totalCostMatchTribune, setTotalCostMatchTribune] = useState();
  const [totalCostMatchLoge, setTotalCostMatchLoge] = useState();

  useEffect(() => {
    axios
      .get(`${host}/api/card/get-cards-statistics`, {})
      .then(function (response) {
        setStatisticsMatchVirage(response.data.data.matchVirage);
        setStatisticMatchGradin(response.data.data.matchGradin);
        setStatisticMatchChinois(response.data.data.matchChinois);
        setStatisticMatchTribune(response.data.data.matchTribune);
        setStatisticMatchLoge(response.data.data.matchLoge);
        setTotalMatchCards(response.data.data.totalMatchCards);
        setCoutTotal(response.data.data.totalCostMatchs);
        setTotalCostMatchVirage(response.data.data.totalCostMatchVirage);
        setTotalCostMatchGradin(response.data.data.totalCostMatchGradin);
        setTotalCostMatchChinois(response.data.data.totalCostMatchChinois);
        setTotalCostMatchTribune(response.data.data.totalCostMatchTribune);
        setTotalCostMatchLoge(response.data.data.totalCostMatchLoge);
      })

      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div
        className={cc(
          styles.header,
          "header bg-gradient-info pb-8 pt-5 pt-md-8"
        )}
      >
        <Container Container>
          <div className="header-body" style={{ paddingLeft: "20px" }}>
            <Row style={{ rowGap: "20px" }}>
              <Col lg="3" xl="2" md="4" xs="4">
                <div
                  style={{
                    textAlign: "center",
                    justifyContent: "center",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    fontSize: "0.9rem",
                    borderRadius: "5px",
                    fontWeight:"bold" ,
                    boxShadow:
                      "0 0 3px 0 rgba(12, 12, 12, 0.25), 0 1px 1px 0 rgba(62, 61, 61, 0.25)",
                    background:
                      "radial-gradient(ellipse farthest-corner at left top, #b3cde0 0%, #99c4d3 20%, #66a1c3 40%, #3388b3 60%, #006494 100%)",
                  }}
                >
                  <h1 className={styles.type}>Total cartes</h1>{" "}
                  {totalMatchCards || 0}
                  <h1 className={styles.type}>coût total</h1>
                  {coutTotal ? coutTotal + " TND" : 0}{" "}
                </div>
              </Col>

              <Col lg="3" xl="2" md="4" xs="4">
                <div className={styles.cardbronze}>
                  <h1 className={styles.type}>CHINOIS</h1>{" "}
                  {statisticMatchChinois?.soldAll
                    ? statisticMatchChinois?.soldAll
                    : 0}
                  <h1 className={styles.type}>coût Total</h1>
                  <h4 className={styles.type}>
                    {totalCostMatchChinois ? totalCostMatchChinois + " TND" : 0}{" "}
                  </h4>
                </div>
              </Col>
              <Col lg="3" xl="2" md="4" xs="4">
                <div className={styles.cardgold}>
                  <h1 className={styles.type}>VIRAGE</h1>
                  {statisticsMatchVirage?.soldAll
                    ? statisticsMatchVirage?.soldAll
                    : 0}

                  <h1 className={styles.type}>coût Total</h1>
                  <h4 className={styles.type}>
                    {totalCostMatchVirage ? totalCostMatchVirage + " TND" : 0}{" "}
                  </h4>
                </div>
              </Col>
              <Col lg="3" xl="2" md="4" xs="4">
                <div className={styles.cardsilver}>
                  <h1 className={styles.type}>GRADIN</h1>{" "}
                  {statisticsMatchGradin?.soldAll
                    ? statisticsMatchGradin?.soldAll
                    : 0}
                  <h1 className={styles.type}>coût Total</h1>
                  <h4 className={styles.type}>
                    {totalCostMatchGradin ? totalCostMatchGradin + " TND" : 0}{" "}
                  </h4>
                </div>
              </Col>
              <Col lg="3" xl="2" md="4" xs="4">
                <div className={styles.cardgold}>
                  <h1 className={styles.type}>TRIBUNE</h1>
                  {statisticMatchTribune?.soldAll
                    ? statisticMatchTribune?.soldAll
                    : 0}{" "}
                  <h1 className={styles.type}>coût Total</h1>
                  <h4 className={styles.type}>
                    {totalCostMatchTribune ? totalCostMatchTribune + " TND" : 0}{" "}
                  </h4>
                </div>
              </Col>
              <Col lg="3" xl="2" md="4" xs="4">
                <div className={styles.cardbronze}>
                  <h1 className={styles.type}>LOGE</h1>

                  {statisticMatchLoge?.soldAll
                    ? statisticMatchLoge?.soldAll
                    : 0}
                  <h1 className={styles.type}>coût Total</h1>
                  <h4 className={styles.type}>
                    {totalCostMatchLoge ? totalCostMatchLoge + " TND" : 0}{" "}
                  </h4>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default MatchType;
